<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/offline/stores"></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.storeDetails.title') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="store">
      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>
            {{ store.retailer.name }} - {{ store.storeType.name }}
          </ion-card-subtitle>
          <ion-card-title>{{ store.name }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-row>
            <ion-col>
              <p>{{ store.address }}</p>
              <p>{{ store.postcode }} {{ store.city }}</p>
              <p>{{ store.country }}</p>
            </ion-col>
            <ion-col class="ion-text-end">
              <p v-if="store.grade">
                <ion-icon :icon="icons.star"></ion-icon>
                {{ $t('labels.grade') }} {{ store.grade }}
              </p>
              <p v-if="store.grade2">
                <ion-icon :icon="icons.star"></ion-icon>
                {{ $t('labels.grade2') }} {{ store.grade2 }}
              </p>
              <p v-if="store.grade3">
                <ion-icon :icon="icons.star"></ion-icon>
                {{ $t('labels.grade3') }} {{ store.grade3 }}
              </p>
              <p v-if="store.code">
                {{ store.retailer.storeCode || this.$t('labels.code') }}
                {{ store.code }}
              </p>
              <p v-if="store.code2">
                {{ this.$t('labels.code2') }}
                {{ store.code2 }}
              </p>
              <p v-if="store.code3">
                {{ this.$t('labels.code3') }}
                {{ store.code3 }}
              </p>
              <p v-if="store.region">
                {{ store.region.name }}
              </p>
            </ion-col>
          </ion-row>
          <div>
            <p v-if="store.email">
              <ion-icon :icon="icons.mailOutline" class="ion-margin-end" />
              <a :href="`mailto:${store.email}`">
                {{ store.email }}
              </a>
            </p>
            <p v-if="store.phone">
              <ion-icon :icon="icons.callOutline" class="ion-margin-end" />
              <a :href="`call:${store.phone}`">
                {{ store.phone }}
              </a>
            </p>
          </div>
        </ion-card-content>
      </ion-card>

      <ion-list lines="none">
        <!-- Surveys -->
        <ion-item button :router-link="`/offline/stores/${store.id}/surveys`">
          <ion-icon :icon="icons.fileTrayFullOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('buttons.surveys') }}
          </ion-label>
        </ion-item>
        <!-- Tickets -->
        <ion-item button :router-link="`/offline/stores/${store.id}/tickets`">
          <ion-icon :icon="icons.ticketOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('buttons.tickets') }}
          </ion-label>
        </ion-item>
        <!-- Contacts -->
        <ion-item button :router-link="`/offline/stores/${store.id}/contacts`">
          <ion-icon :icon="icons.peopleOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('buttons.contacts') }}
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState } from 'vuex';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCardHeader,
  IonRow,
  IonCol,
  IonIcon,
} from '@ionic/vue';
import {
  mailOutline,
  callOutline,
  fileTrayFullOutline,
  ticketOutline,
  calendarOutline,
  documentOutline,
  prismOutline,
  peopleOutline,
  star,
} from 'ionicons/icons';

export default {
  name: 'OfflineStoreDetails',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonLabel,
    IonButtons,
    IonBackButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonRow,
    IonCol,
    IonIcon,
  },
  data() {
    return {
      icons: {
        mailOutline,
        callOutline,
        fileTrayFullOutline,
        ticketOutline,
        calendarOutline,
        documentOutline,
        prismOutline,
        peopleOutline,
        star,
      },
    };
  },
  computed: {
    ...mapState('stores', {
      store: 'selectedStore',
    }),
  },
};
</script>
